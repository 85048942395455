<script setup lang="ts"></script>

<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_308_373)">
            <path
                d="M10.582 9C10.582 9.87369 9.87369 10.582 9 10.582C8.12631 10.582 7.41797 9.87369 7.41797 9C7.41797 8.12631 8.12631 7.41797 9 7.41797C9.87369 7.41797 10.582 8.12631 10.582 9Z"
                fill="currentColor"
            />
            <path
                d="M11.6719 4.21875H6.32812C5.16495 4.21875 4.21875 5.16495 4.21875 6.32812V11.6719C4.21875 12.8351 5.16495 13.7812 6.32812 13.7812H11.6719C12.8351 13.7812 13.7812 12.8351 13.7812 11.6719V6.32812C13.7812 5.16495 12.8351 4.21875 11.6719 4.21875ZM9 11.6367C7.5461 11.6367 6.36328 10.4539 6.36328 9C6.36328 7.5461 7.5461 6.36328 9 6.36328C10.4539 6.36328 11.6367 7.5461 11.6367 9C11.6367 10.4539 10.4539 11.6367 9 11.6367ZM12.0234 6.50391C11.7322 6.50391 11.4961 6.26784 11.4961 5.97656C11.4961 5.68529 11.7322 5.44922 12.0234 5.44922C12.3147 5.44922 12.5508 5.68529 12.5508 5.97656C12.5508 6.26784 12.3147 6.50391 12.0234 6.50391Z"
                fill="currentColor"
            />
            <path
                d="M13.2539 0H4.74609C2.12915 0 0 2.12915 0 4.74609V13.2539C0 15.8708 2.12915 18 4.74609 18H13.2539C15.8708 18 18 15.8708 18 13.2539V4.74609C18 2.12915 15.8708 0 13.2539 0ZM14.8359 11.6719C14.8359 13.4165 13.4165 14.8359 11.6719 14.8359H6.32812C4.5835 14.8359 3.16406 13.4165 3.16406 11.6719V6.32812C3.16406 4.5835 4.5835 3.16406 6.32812 3.16406H11.6719C13.4165 3.16406 14.8359 4.5835 14.8359 6.32812V11.6719Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_308_373">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
